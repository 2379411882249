/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.css';

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

.text-center {
  text-align: center;
}
.platform_menu {
  width: 100%;
  max-width: 100px;
}
/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
